import ScreenHeader from "../Components/MainContent"
import { useNavigate } from "react-router-dom"
import './style.css'
import { Card } from "asbawa-react-ui-ts-test-b"
import MainContent from "../Components/MainContent"
import { SubHeader } from "../Components"
import { IconCommunication, IconDocuments, IconGoogleDrive, IconServices, IconStripe, IconSubscriptions, IconTasks, IconUser, IconUsers } from "../Components/Icons"
import { ReactNode } from "react"
import GoogleDriveLogo  from './../../assets/logos/GoogleDrive.png'
import COLOR from "../../constants/palette"
const iconColor = COLOR.primary
const Settings = () => {
    return <MainContent title="Settings">
        <br />
        <SubHeader heading="General Settings" />
        <div className="setting-wrapper">
            <SettCard iconContent={<IconUser color={iconColor} size="2.2em"/>} title="Profile" to="/settings/profile" description="Update company profile details and company logo" />
            <SettCard iconContent={<IconUsers  color={iconColor} size="2.2em"/>} title="Client Custom Fields" to="/settings/client-profile-custom-fields" description="Manage client profile related custom fields" />
            <SettCard iconContent={<IconDocuments  color={iconColor} size="2.2em"/>} title="Documents" to="/settings/documents-types" description="Manage client document types list" />
            <SettCard iconContent={<IconServices  color={iconColor} size="2.2em"/>} title="Services" to="/settings/services-types" description="Manage services types that you offers" />
            <SettCard iconContent={<IconCommunication  color={iconColor} size="2.2em"/>} title="Communication" to="/settings/communication/mailer" description="Setup Mailer & manage emails templates" />
            <SettCard iconContent={<IconTasks  color={iconColor}size="2.2em"/>} title="Tasks" to="/settings/tasks/status" description="Manage Task status" />
            <SettCard iconContent={<IconSubscriptions color={iconColor} size="2.2em"/>} title="Subscriptions" to="/settings/plans" description="Choose your Subscription" />
            {/* <SettCard title="Email Templates" to="/settings/communication/email/templates" description="alfalsfdlakslfd"/> */}
        </div>
        <br />
        <SubHeader heading="Plugin & Features Settings" />
        <div className="setting-wrapper">
            <SettCard iconContent={<IconStripe  color={COLOR.stripe} size="2.2em"/>} title="Stripe" to="/settings/stripe" description="Configure/ Mangage you stripe details to collect online payments from client" />
            <SettCard iconContent={<img width={"40px"} src={GoogleDriveLogo}/>} title="Google Drive" to="/settings/google-drive" description="Configure/ Mangage you google drive details to collect documents from client" />
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </MainContent>
}

export default Settings

const SettCard = (props: { title: string, to: string, description?: string,iconContent:ReactNode }) => {
    const navigate = useNavigate()
    return <div className="setting-item" onClick={() => navigate(props.to)}>
        <div className="setting-item-icon">{props.iconContent}</div>
        <div className="setting-item-content">
            <h3 className="setting-item-title">{props.title}</h3>
            <p className="setting-item-desc">{props?.description}</p>
        </div>
    </div>
}