import { BrowserRouter, Outlet, useRoutes } from "react-router-dom"
import PanelLayout from "../screens/Layouts/PanelLayout"
import Settings from "../screens/Settings"
import Clients from "../screens/Clients"
import AuthLayout from "../screens/Auth/AuthLayout"
import Login from "../screens/Auth/Login"
import Signup from "../screens/Auth/Signup"
import ClientLayout from "../screens/Clients/ClientLayout/ClientLayout"
import ClientProfile from "../screens/Clients/ClientProfile"
import ClientServices from "../screens/Clients/ClientServices"
import ClientDocuments from "../screens/Clients/ClientDocuments"
import ClientServiceDetails from "../screens/Clients/ClientServices/ClientServiceDetails"
import SettingsLayout from "../screens/Settings/SettingsLayout"
import ProfileSettings from "../screens/Settings/ProfileSettings"
import EmailsSettings from "../screens/Settings/EmailsSettings"
import StripeSettings from "../screens/Settings/StripeSettings"
import GoogleDriveSettings from "../screens/Settings/GoogleDriveSettings"
import ListDocumentsTypes from "../screens/Settings/DocumentsTypesSettings"
import ClientDocumentsRequest from "../screens/Clients/ClientDocuments/ClientDocumentsRequest"
import ClientUploadDocuments from "../screens/Clients/ClientDocuments/ClientUploadDocuments"
import ListServicesTypes from "../screens/Settings/ServicesTypesSettings"
import DetailServicesTypes from "../screens/Settings/ServicesTypesSettings/DetailServicesTypes"
import Dashboard from "../screens/Dashboard"
import Tasks from "../screens/TasksManagement/Tasks"
import TaskDetails from "../screens/TasksManagement/Tasks/TaskDetails"
import DumyUi from "../screens/DumyUi"
import DetailDocumentsTypes from "../screens/Settings/DocumentsTypesSettings/DetailDocumentsTypes"
import ListClientProfileCustomFields from "../screens/Settings/ClientProfileCustomFieldsSettings"
import DetailClientProfileCustomFields from "../screens/Settings/ClientProfileCustomFieldsSettings/DetailClientProfileCustomFields"
import MailerSettings from "../screens/Settings/MailerSettings"
import ClientPaymentStatus from "../screens/Clients/ClientPayments/ClientPaymentStatus"
import EmailTemplates from "../screens/Settings/Communication/Emails/Templates"
import DetailEmailTemplates from "../screens/Settings/Communication/Emails/Templates/DetailEmailTemplates"
import AddEmailTemplates from "../screens/Settings/Communication/Emails/Templates/AddEmailTemplates"
import CommunicationLayout from "../screens/Settings/Communication/CommunicationLayout"
import TaskScheduleRecurrences from "../screens/TasksManagement/TaskSchedulesRecurrences"
import TaskScheduleRecurrenceDetail from "../screens/TasksManagement/TaskSchedulesRecurrences/TaskScheduleRecurrenceDetail"
import TaskSchedules from "../screens/TasksManagement/TaskSchedules"
import TaskScheduleDetail from "../screens/TasksManagement/TaskSchedules/TaskScheduleDetail"
import ListTaskStatus from "../screens/Settings/TasksStatus"
// import ReminderSchedules from "../screens/TasksManagement/ReminderSchedules"
// import ReminderScheduleDetail from "../screens/TasksManagement/ReminderSchedules/ReminderScheduleDetail"
import ReminderDetails from "../screens/TasksManagement/Reminders/ReminderDetails"
import Reminders from "../screens/TasksManagement/Reminders"
import ClientInvoices from "../screens/Clients/ClientInvoices"
import DetailInvoice from "../screens/Clients/ClientInvoices/DetailInvoice"
import ClientTransactions from "../screens/Clients/ClientTransactions"
import NewPassword from "../screens/Auth/NewPassword"
import ForgotPassword from "../screens/Auth/ForgotPassword"
import VerifyEmail from "../screens/Auth/VerifyEmail"
import SendVerifyEmailOtp from "../screens/Auth/SendVerifyEmailOtp"
import Plans from "../screens/Settings/Plans"
import PaymentRequests from "../screens/Clients/ClientPayments/PaymentRequests"
import DocumentRequests from "../screens/Clients/ClientDocuments/DocumentRequests"
import ListUser from "../screens/Users"
import ListMeeting from "../screens/Meetings"
import EditEmailTemplates from "../screens/Settings/Communication/Emails/Templates/EditEmailTemplates"
import CommunicationRecurrenceDetail from "../screens/TasksManagement/CommunicationRecurrences/CommunicationRecurrenceDetail"
import ClientCommunicationDetail from "../screens/Clients/ClientCommunication/ClientCommunicationDetail"
import ClientCommunication from "../screens/Clients/ClientCommunication"
import ClientTasks from "../screens/Clients/ClientTasks"
import EmailFooter from "../screens/Settings/Communication/Emails/EmailFooter"

const AllRoutes = () => {
  const routes = [
    {
      path: '/auth', element: <Outlet />,
      children: [
        { index: true, element: <Login /> },
        { path: 'signup', element: <Signup /> },
        { path: 'send-verifyemail-otp', element: <SendVerifyEmailOtp /> },
        { path: 'verify-email', element: <VerifyEmail /> },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'new-password/:email', element: <NewPassword /> }
      ]
    },

    { path: '/upload-documents/:token', element: <ClientUploadDocuments /> },
    { path: '/client/payment/status', element: <ClientPaymentStatus /> },
    { path: '/company/payment/status', element: <ClientPaymentStatus /> },


    {
      path: '/', element: <PanelLayout />,
      children: [
        { index: true, element: <Dashboard /> },
        { path: 'clients', element: <Clients /> },
        { path: 'dumy-ui', element: <DumyUi /> },
        {
          path: 'client/:client_id', element: <ClientLayout />,
          children: [
            { index: true, element: <ClientProfile /> },
            { path: 'profile', element: <ClientProfile /> },
            {
              path: 'services', element: <Outlet />,
              children: [
                { index: true, element: <ClientServices /> },
                { path: ':client_service_id/view', element: <ClientServiceDetails /> },
              ]
            },
            { path: 'documents', element: <ClientDocuments /> },
            { path: 'communication', element: <ClientCommunication /> },
            { path: 'communication/:communication_id', element: <ClientCommunicationDetail /> },
            { path: 'task', element: <ClientTasks /> },
            { path: 'documents/request', element: <ClientDocumentsRequest /> },
            { path: 'invoices', element: <ClientInvoices /> },
            { path: 'invoices/:invoice_id', element: <DetailInvoice /> },
            // { path: 'transactions', element: <ClientTransactions /> },
            { path: 'payment-requests', element: <PaymentRequests /> },
            { path: 'document-requests', element: <DocumentRequests /> },
          ]
        },
        {
          path: 'settings', element: <SettingsLayout />,
          children: [
            { index: true, element: <Settings /> },
            { path: 'profile', element: <ProfileSettings /> },
            { path: 'plans', element: <Plans /> },
            { path: 'emails', element: <EmailsSettings /> },
            { path: 'stripe', element: <StripeSettings /> },
            { path: 'google-drive', element: <GoogleDriveSettings /> },
            { path: 'documents-types', element: <ListDocumentsTypes /> },
            { path: 'documents-types/:document_type_id', element: <DetailDocumentsTypes /> },
            { path: 'services-types', element: <ListServicesTypes /> },
            { path: 'services-types/:service_type_id', element: <DetailServicesTypes /> },
            { path: 'client-profile-custom-fields', element: <ListClientProfileCustomFields /> },
            { path: 'client-profile-custom-fields/:client_profile_custom_field_id', element: <DetailClientProfileCustomFields /> },
            {
              path: 'communication', element: <CommunicationLayout />,
              children: [
                { index: true, path: 'mailer', element: <MailerSettings /> },
                { path: 'email/templates/edit/:email_template_id', element: <EditEmailTemplates /> },
                { path: 'email/templates/add', element: <AddEmailTemplates /> },
                { path: 'email/templates/:email_template_id', element: <DetailEmailTemplates /> },
                { path: 'email/templates', element: <EmailTemplates /> },
                { path: 'email/footer', element: <EmailFooter /> },
              ]
            },
            { path: 'tasks/status', element: <ListTaskStatus /> },
            // { path: 'tasks/status/:task_status_id', element: <DetailTaskStatus /> },
          ]
        },
        {path:'reminders',element:<Outlet/>,
          children:[
            { index: true, element: <Reminders /> },
            {path:':reminder_id',element:<ReminderDetails/>}
          ]
        },
        {
          path: 'tasks', element: <Outlet />,
          children: [
            { index: true, element: <Tasks /> },
            { path: ':task_id', element: <TaskDetails /> },

            // { path: 'reminder/schedules', element: <ReminderSchedules /> },
            // { path: 'reminder/schedule/:reminder_schedule_id', element: <ReminderScheduleDetail /> },
            ,
            // { path: 'reminder/:reminder_id', element: <ReminderDetails /> },


            { path: 'schedule/recurrences', element: <TaskScheduleRecurrences /> },
            { path: 'schedule/recurrence/:task_schedule_recurrence_id', element: <TaskScheduleRecurrenceDetail /> },
            // { path: 'schedules', element: <TaskSchedules /> },
            // { path: 'schedule/:task_schedule_id', element: <TaskScheduleDetail /> },

            { path: 'communication/recurrence/:task_schedule_recurrence_id', element: <CommunicationRecurrenceDetail /> },
          ]
        },
        {
          path: 'users', element: <Outlet />,
          children: [
            { index: true, element: <ListUser /> },
            // { path: ':user_id', element: <UserDetails /> },
          ]
        },
        {
          path: 'meetings', element: <Outlet />,
          children: [
            { index: true, element: <ListMeeting /> },
          ]
        },
        // { path: 'tasks', element: <Tasks /> },
        { path: 'todos', element: <h1>Todos</h1> },

      ]

    },



    { path: '*', element: <h1>error 404</h1> }

  ]


  return useRoutes(routes)
}

function BaseRouter() {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  )
}

export default BaseRouter;