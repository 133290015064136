import {
  Button,
  ButtonsGroup,
  CardHeader,
  CheckboxInline,
  Dropdown,
  FieldGroup,
  Input,
  InputCheckbox,
  List,
  ListBody,
  ListHeadType,
  Modal,
  errorPopUp,
  gLoader,
  successPopUp,
} from "asbawa-react-ui-ts-test-b";
import { FC, useEffect, useState } from "react";
import { ModalFormProps } from "../../../types";

import paymentService from "../../../services/axios/payments";

const AskClientPayment: FC<ModalFormProps> = ({
  show,
  onCancel = () => null,
  onSuccess = () => null,
  data
}): JSX.Element | null => {
  const [payload, setPayload] = useState<any>({ client_id: data?.client_id, invoice_ids: [] });
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [list, setList] = useState<any>([]);
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = () => {
    gLoader(true);
    paymentService?.invoiceListForAskPayment(data?.client_id, {})
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        // setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        // setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleCheckboxChange = (value: any, name: string, id: any, amt: number) => {
    let totalAmt = amount;
    if (value) {
      totalAmt = totalAmt + amt;
    } else {
      if (totalAmt > 0) {
        totalAmt = totalAmt - amt;
      } else {
        totalAmt = 0;
      }
    }
    setAmount(totalAmt);
    const updatedInvoiceIds = value
      ? [...payload.invoice_ids, id]
      : payload.invoice_ids.filter((invoiceId: number) => invoiceId !== id);
    setPayload({
      ...payload,
      invoice_ids: updatedInvoiceIds,
    });
  };

  const handleChange = (value: any, name: any) => {
    setPayload({
      ...payload,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null
    })
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    paymentService?.askClientForPayment(payload)
      .then((res) => {
        setButtonLoading(false);
        successPopUp(res?.data?.message);
        onSuccess();
      })
      .catch((err) => {
        setButtonLoading(false);
        errorPopUp(err?.message);
        setErrors(err?.field_errors);
      })
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal show={show} style={{ maxWidth: "600px" }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}><CardHeader title="Ask Payment" /><span style={{ float: "right" }}><b>Amount:  </b>{amount}</span></div>
        <br />
        <FieldGroup column={1}>
          {/* <Input
            label="Amount"
            name="amount"
            handleChange={handleChange}
            value={payload?.amount}
            error={errors?.amount}
          /> */}
          <CheckboxInline
            label="Notify on Email"
            name="notify_on_email"
            handleChange={handleChange}
            checked={payload?.notify_on_email}
          />

          <br />
        </FieldGroup>
        <List headers={listHeaders}>
          <ListBody>
            {list.map((itm: any, index: number) => {
              const isChecked = payload?.invoice_ids.includes(itm?.id);
              return (
                <tr key={index}>
                  <td style={{ textAlign: 'center' }}>{itm.id}</td>
                  <td style={{ textAlign: 'center' }}>{itm.cuid}</td>
                  <td style={{ textAlign: 'center' }}>{itm?.total_amount}</td>
                  {/* <td style={{ textAlign: 'center' }}>{utcToLocalDatetime(itm?.invoice_date)}</td> */}
                  <td style={{ textAlign: "center" }}>
                    <InputCheckbox
                      label=''
                      name={`invoice_id_checkbox_${itm.id}`}
                      handleChange={(value, name) => { handleCheckboxChange(value, name, itm.id, itm.total_amount) }}
                      // handleChange={(e: any) => handleCheckboxChange(itm.id, e.target.checked)}
                      checked={isChecked}
                    />
                  </td>
                </tr>
              );
            })}
          </ListBody>
        </List>

        <br />

        <ButtonsGroup>
          <Button title="Send Request" onClick={handleSubmit} loading={buttonLoading} theme="success" />
          <Button title="Cancel" onClick={() => onCancel()} theme="danger" />
        </ButtonsGroup>
      </Modal>
    </>
  );
};

export default AskClientPayment;
const listHeaders: ListHeadType[] = [
  {
    title: "Id",
    width: "5%",
    sortName: "uid",
    align: "center",
  },
  {
    title: "Cuid",
    width: "15%",
    align: "center",
  },
  {
    title: "Total Amount",
    width: "20%",
    align: "center",
  },
  // {
  //   title: "Invoice Date",
  //   width: "20%",
  //   align: "center",
  // },
  {
    title: "Actions",
    width: "20%",
    align: "center",
  }
];