
import { Button, Card, CardHeader, confirmPopUp, errorPopUp, gLoader, Input, List, ListBody, ListHeadType, successPopUp, Td } from "asbawa-react-ui-ts-test-b";
import { Badge, TextLink } from "../../Components";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../../services/axios/settings/companyServices";
import AddClientService from "./AddClientService";
import ListRowActions, { ListPagination, ListSearch } from "../../Components/List";
import { IconDocuments } from "../../Components/Icons";
import { useListPagination } from "../../../hooks/useListPagination";
import { useListFilters } from "../../../hooks/useListFilters";
import { useListSorting } from "../../../hooks/useListSorting";
import { toYesNo } from "../../../utils/commonFunctions";
import { CS_BOOLEAN_TRUE } from "../../../utils/constants";
const ClientServices = (): JSX.Element | null => {
  const { client_id } = useParams<{ client_id: string }>();
  const [search, setSearch] = useState<any>('');
  const [list, setList] = useState<any>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<any>(null);
  const [totalCount, setTotalCount] = useState<any>(null);
  const { pageNo, pageSize, setPageNo, setPageSize } = useListPagination({})
  const { filters, setFilter } = useListFilters({})
  const { sortByName, sortByOrder, setSortByOrder, setSortByName } = useListSorting({})
  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
  }, [client_id, pageNo, pageSize,search])

  const handleSubmit = () => {
    fetchData();
    setShowAddNew(false);
  };

  const fetchData = () => {
    gLoader(true);
    service?.listCompanyServiceToClient('', { client_id, pageNo, pageSize, search: search })
      .then((res: any) => {
        gLoader(false);
        setList(res?.data?.response?.list);
        setTotalPages(res?.data?.response?.dataFilter?.totalPages);
        setTotalCount(res?.data?.response?.dataFilter?.totalCount);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const handleDelete = (id: any) => {
    gLoader(true);
    service?.deleteCompanyServiceToClient(id)
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  };

  return <>
    <Card style={{maxWidth:'800px'}}>
      <CardHeader
        title="Client Services"
        rightButtons={
          <>
            <Button
              size="small"
              title="Add New"
              theme="primary"
              onClick={() => setShowAddNew(true)}
            />
          </>
        }
      />

      {showAddNew && (
        <AddClientService
          show={showAddNew}
          onCancel={() => setShowAddNew(false)}
          onSuccess={handleSubmit}
          data={{ client_id: client_id }}
        />
      )}
      {/* <ListSearch /> */}
      <List headers={listHeaders}>
        <ListBody>
          {list.map((itm: any, index: number) => {
            return (
              <tr key={index}>
                <Td align="left"><TextLink text={itm?.services_type_title} title="View profile" goto={`${itm.id}/view`} /></Td>
                <Td>{toYesNo(CS_BOOLEAN_TRUE.includes(itm?.has_recurrence))}</Td>
                <Td><ListRowActions
                  actions={[
                    { icon: 'view', onClick: () => navigate(`${itm.id}/view`) },
                    { icon: 'delete', onClick: () => confirmPopUp('Are you sure you want to delete ?', () => handleDelete(itm?.id)) },
                  ]}
                /></Td>
              </tr>
            );
          })}
        </ListBody>
      </List>
      {/* <ListPagination totalCount={totalCount} pageSize={pageSize} setPageSize={setPageSize} pageNo={pageNo} setPageNo={setPageNo} totalPages={totalPages} /> */}
    </Card>
  </>
};

export default ClientServices;
const listHeaders: ListHeadType[] = [
  {
    title: "Title",
    width: "60%",
    align: "left",
  },
  {
    title: "Has Recurrence",
    width: "20%",
    align: "left",
  },
  {
    title: "",
    width: "20%",
    align: "right",
  }
];