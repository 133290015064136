import { Button, CheckboxInline, InputCheckbox, Label } from "asbawa-react-ui-ts-test-b"
import { useState } from "react"

const DumyUi =()=>{

    const [payload,setPayload] = useState<any>({})

    const handleChange=(value:any,name:string)=>{
        setPayload({
            ...payload,
            [name]:value
        })
    }

return <>
 <h1>Buttons</h1>
    <Button 
        theme="primary" // primary | success | danger
        title="Norma"
        onClick={()=>alert('test')}
    />
    <Button 
        theme="success"
        title="Loading"
        onClick={()=>alert('test')}
        loading  // will disable an show loader
    />
    <Button 
        title="Diabled"
        onClick={()=>alert('test')}
        disabled // disables but no loader
    />
    <Button
        size="small" // to show small button
        title="Small button"
        theme="danger"
        onClick={()=>alert('test')}
    />

    <br/>
    <br/>
    <br/>
    <br/>

    <h1>Checkbox</h1>
    <p>when need to add checkbox as normal input with label</p>
    <InputCheckbox 
        label="Is Required"
        name="is_required"
        handleChange={handleChange}
        checked={payload?.is_required}
    />

    <br/>
    <CheckboxInline
    label="Are you agree with terms ?"
    name="is_agree"
    handleChange={handleChange}
    checked={payload?.is_agree}
    />

<br/>
    <br/>
    <br/>
    <br/>
    <h1>Only label without input</h1>
    <Label text="User name"/>
    
</>

}

export default DumyUi