import { Button, Card, CardHeader, errorPopUp, gLoader } from "asbawa-react-ui-ts-test-b"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import service from "../../../services/axios/settings/companyServices";
import EditClientService from ".";
import { Badge, LabelDescriptions, SubContent } from "../../Components";
import { CS_BOOLEAN_TRUE, CS_INVOICE_STATUS, CS_PAYMENT_STATUS } from "../../../utils/constants";
import paymentService from "../../../services/axios/payments";
import TableForm from "../../Components/TableForm";
import EditInvoice from "./EditInvoice";
import { utcToLocalDatetime } from "../../../utils/dateFunctions";

const DetailInvoice = () => {
  const { invoice_id, client_id } = useParams<{ invoice_id: string, client_id: string }>();
  const [showEdit, setshowEdit] = useState<boolean>(false);
  const [invoiceDetail, setInvoiceDetail] = useState<any>([]);

  const handleEditSuccess = () => {
    setshowEdit(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [invoice_id])

  const fetchData = () => {
    gLoader(true);
    paymentService?.detailInvoice(invoice_id)
      .then((res: any) => {
        gLoader(false);
        setInvoiceDetail(res?.data?.response?.detail)
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      })
  }

  const invStatus: any = CS_INVOICE_STATUS.find((field: any) => field.value == invoiceDetail.invoice_status);
  const invStatusBgColor = invStatus?.bgColor;
  const invStatusColor = invStatus?.color;
  const invStatusName = invStatus?.name;

  return <Card>
    <CardHeader title='Invoice' rightButtons={
      <>
        <Button
          size="small"
          title="Edit"
          onClick={() => setshowEdit(true)}
        /></>
    } />

    {showEdit && (
      <EditInvoice
        show={showEdit}
        onCancel={() => setshowEdit(false)}
        onSuccess={handleEditSuccess}
        data={{ invoice_id, client_id }}
      />
    )}

      <LabelDescriptions
      column={2}
        list={[
          ["CUID", invoiceDetail?.cuid],
          ["Amount", invoiceDetail?.total_amount],
          ["Invoice Date", utcToLocalDatetime(invoiceDetail?.invoice_date)],
          ["Status", <Badge color={invStatusColor} bgColor={invStatusBgColor} text={invStatusName} />],
        ]}
      />

<br/>
<br/>
<br/>
    <SubContent heading="Items" style={{maxWidth:'500px'}}>
      <TableForm headers={[
        { title: 'Title', width: '40%' },
        { title: 'Price', width: '20%' },
      ]}>
        {invoiceDetail?.items?.map((item: any, index: number) => (
          <tr>
            <td>{item?.title}</td>
            <td>{item?.unit_price}</td>
          </tr>
        ))}
      </TableForm>
    </SubContent>
  </Card>
}


export default DetailInvoice