
import { IoSendSharp, IoSettingsOutline } from "react-icons/io5";
import { RiTodoLine } from "react-icons/ri";
import { LuUsers2, LuLayoutDashboard } from "react-icons/lu";
import { IoIosEye, IoMdAdd } from "react-icons/io";
import { IoEyeOutline,IoDocumentsOutline } from "react-icons/io5";
// import { FaCaretRight, FaCaretUp } from "react-icons/fa";
// import { FaCaretLeft } from "react-icons/fa";
// import {AiOutlineLogout} from '@react-icons/all-files/ai/AiOutlineLogout.esm'
// import { BiSolidMessage } from "react-icons/bi";
// import { FiMail, FiPlus } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { IconContext } from "react-icons";
import { MdModeEdit } from "react-icons/md";
// import { IoMdArrowRoundBack } from "react-icons/io";
// import { FaCaretDown } from "react-icons/fa";
// import { FaHourglassStart } from "react-icons/fa";
// import { FaTruck } from "react-icons/fa";
// import { TfiReload } from "react-icons/tfi";
// import { BsPatchCheckFill } from "react-icons/bs";
// import { FaBan } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { FaRegHandshake } from "react-icons/fa6";
import { MdAccessAlarm, MdDashboard, MdNotes } from "react-icons/md";
import { FaFileDownload, FaRegFile, FaRegFileImage, FaRegFilePdf, FaRegFolder, FaRegUser, FaTasks, FaUsers, FaUserSecret } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { SlOptionsVertical } from "react-icons/sl";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidLeftArrow } from "react-icons/bi";
import { MdOutlineCopyAll } from "react-icons/md";
import { GiCheckMark } from "react-icons/gi";
import { GiCrossMark } from "react-icons/gi";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { LiaMailBulkSolid } from "react-icons/lia";
import { MdCurrencyExchange } from "react-icons/md";
import { BsStripe } from "react-icons/bs";
import { FaGoogleDrive } from "react-icons/fa";
const Icon=(props)=>{
    return <IconContext.Provider value={{ className: "global-class-name",color:'grey',...props, }}>
    {
        props?.onClick 
        ? <span title={props?.title} style={{cursor:"pointer"}} onClick={props?.onClick}>
        {props?.children}
      </span>
        :<span title={props?.title}>
        {props?.children}
      </span>
    }
    
    
  </IconContext.Provider>
}


// export const IconSendMessage=(props)=>{

//     return <Icon {...props}>
//         <IoSendSharp/>
//     </Icon>
// }

// export const IconAddNew=(props)=>{

//   return <Icon {...props}>
//       <IoMdAdd size={30} color="black"/>
//   </Icon>
// }

export const IconView=(props)=>{

  return <Icon {...props}>
      <IoEyeOutline/>
  </Icon>
}

export const IconAngleLeft=(props)=>{

  return <Icon {...props}>
      <IoIosArrowBack/>
  </Icon>
}


export const IconNextSolid=(props)=>{

  return <Icon {...props}>
      <BiSolidRightArrow/>
  </Icon>
}

export const IconPrevSolid=(props)=>{

  return <Icon {...props}>
      <BiSolidLeftArrow/>
  </Icon>
}

export const IconDashboard=(props)=>{

    return <Icon {...props}>
        <LuLayoutDashboard/>
    </Icon>
  }

  export const IconClients=(props)=>{

    return <Icon {...props}>
        <LuUsers2/>
    </Icon>
  }

  export const IconUsers=(props)=>{

    return <Icon {...props}>
        <LuUsers2/>
    </Icon>
  }

  
  export const IconServices=(props)=>{

    return <Icon {...props}>
        <FaRegHandshake/>
    </Icon>
  }

  export const IconDocuments=(props)=>{

    return <Icon {...props}>
        <IoDocumentsOutline/>
    </Icon>
  }

  export const IconUser=(props)=>{

    return <Icon {...props}>
        <FaRegUser/>
    </Icon>
  }

  export const IconReminder=(props)=>{

    return <Icon {...props}>
        <MdAccessAlarm/>
    </Icon>
  }

  export const IconSettings=(props)=>{

    return <Icon {...props}>
        <IoSettingsOutline/>
    </Icon>
  }

  
  export const IconPdf=(props)=>{

    return <Icon color="red" {...props}>
        <FaRegFilePdf/>
    </Icon>
  }


  
  export const IconImage=(props)=>{

    return <Icon color="green" {...props}>
        <FaRegFileImage/>
    </Icon>
  }

  
  export const IconFolder=(props)=>{

    return <Icon color="#d1ac06" {...props}>
        <FaRegFolder/>
    </Icon>
  }

  export const IconFile=(props)=>{

    return <Icon color="grey" {...props}>
        <FaRegFile/>
    </Icon>
  }

  export const IconFileDownload=(props)=>{

    return <Icon color="#43802a" {...props}>
        <FaFileDownload/>
    </Icon>
  }

  
  export const IconTasks=(props)=>{

    return <Icon color="grey" {...props}>
        <FaTasks/>
    </Icon>
  }

  
  export const IconTodo=(props)=>{

    return <Icon color="grey" {...props}>
        <RiTodoLine/>
    </Icon>
  }
export const IconEdit=(props)=>{

  return <Icon {...props}>
      <MdModeEdit/>
  </Icon>
}

export const IconDelete=(props)=>{

  return <Icon color="#b13333" {...props}>
      <FiTrash2/>
  </Icon>
}

export const IconOptions=(props)=>{

  return <Icon {...props}>
      <SlOptionsVertical/>
  </Icon>
}


export const IconCopy=(props)=>{

  return <Icon {...props}>
      <MdOutlineCopyAll/>
  </Icon>
}

export const IconYes=(props)=>{

  return <Icon color='green' {...props}>
      <GiCheckMark/>
  </Icon>
}

export const IconNo=(props)=>{
  return <Icon color="red" {...props}>
      <GiCrossMark />
  </Icon>
}


export const IconMeeting=(props)=>{
  return <Icon {...props}>
      <RiCalendarScheduleLine />
  </Icon>
}

export const IconCommunication=(props)=>{
  return <Icon {...props}>
      <LiaMailBulkSolid />
  </Icon>
}

export const IconSubscriptions=(props)=>{
  return <Icon {...props}>
      <MdCurrencyExchange />
  </Icon>
}


export const IconStripe=(props)=>{
  return <Icon {...props}>
      <BsStripe />
  </Icon>
}

export const IconGoogleDrive=(props)=>{
  return <Icon {...props}>
      <FaGoogleDrive />
  </Icon>
}


// export const IconNextList=(props)=>{

//   return <Icon {...props}>
//       <FaCaretRight/>
//   </Icon>
// }

// export const IconPrevList=(props)=>{

//   return <Icon {...props}>
//       <FaCaretLeft/>
//   </Icon>
// }

// export const IconLogout=(props)=>{

//   return <Icon color="#fff" {...props}>
//       <AiOutlineLogout />
//   </Icon>
// }

// export const IconMessage=(props)=>{

//   return <Icon {...props}>
//       <BiSolidMessage />
//   </Icon>
// }

// export const IconMail=(props)=>{

//   return <Icon {...props}>
//       <FiMail />
//   </Icon>
// }

// export const IconBack=(props)=>{

//   return <Icon {...props}>
//       <IoMdArrowRoundBack />
//   </Icon>
// }

// export const IconDown=(props)=>{

//   return <Icon {...props}>
//       <FaCaretDown />
//   </Icon>
// }

// export const IconUp=(props)=>{

//   return <Icon {...props}>
//       <FaCaretUp />
//   </Icon>
// }

// export const IconStar=(props)=>{

//   return <Icon {...props}>
//       <FaHourglassStart />
//   </Icon>
// }

// export const IconTruck=(props)=>{

//   return <Icon {...props}>
//       <FaTruck />
//   </Icon>
// }

// export const IconLoad=(props)=>{

//   return <Icon {...props}>
//       <TfiReload />
//   </Icon>
// }

// export const IconDone=(props)=>{

//   return <Icon {...props}>
//       <BsPatchCheckFill />
//   </Icon>
// }

// export const IconBan=(props)=>{

//   return <Icon {...props}>
//       <FaBan />
//   </Icon>
// }