import { Button, Card, CardHeader, Modal, confirmPopUp, errorPopUp, gLoader, successPopUp } from "asbawa-react-ui-ts-test-b";
import { useNavigate, useParams } from "react-router-dom";
import './ClientDocuments.css';
import { IconDelete, IconFileDownload, IconFolder, IconImage, IconOptions, IconPdf, IconFile } from "../../Components/Icons";
import { useEffect, useRef, useState } from "react";
import documentService from "../../../services/axios/documents";
import ClientDocumentsRequest from "./ClientDocumentsRequest";
import ClientDocumentsAddFolder from "./ClientDocumentsAddFolder";
import ClientDocumentView from "./ClientDocumentView";

const ClientDocuments = (): JSX.Element | null => {
  const [showNewRequest, setShowNewRequest] = useState(false);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const { client_id } = useParams<{ client_id: string }>();
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [folderList, setFolderList] = useState<any[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [queryParams, setQueryParams] = useState<{ client_id: string | undefined; folder_id?: string }>({
    client_id: client_id,
  });
  const navigate = useNavigate();
  useEffect(() => {
    fetchData();
  }, [client_id, queryParams]);

  const fetchData = () => {
    gLoader(true);
    documentService.listDocument('', queryParams)
      .then((res: any) => {
        gLoader(false);
        setDocumentList(res?.data?.response?.list || []);
        setFolderList(res?.data?.response?.folder || []);
      })
      .catch((err: any) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const handleDeleteDocument = (document_id: number, drive_document_id: string) => {
    gLoader(true);
    documentService.deleteDocument(document_id, { client_id, drive_document_id })
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const handleDeleteDocumentFolder = (folder_id: number) => {
    gLoader(true);
    documentService.deleteDocumentsFolder(folder_id, { client_id })
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const handleDeleteMultiDocuments = () => {
    gLoader(true);
    documentService.deleteMultiDocuments({ document_ids: selectedDocuments })
      .then((res) => {
        gLoader(false);
        successPopUp(res?.data?.message);
        fetchData();
      })
      .catch((err) => {
        gLoader(false);
        errorPopUp(err?.message);
      });
  };

  const toggleSelectDocument = (document_id: number) => {
    setSelectedDocuments((prevSelectedDocuments) =>
      prevSelectedDocuments.includes(document_id)
        ? prevSelectedDocuments.filter((id) => id !== document_id)
        : [...prevSelectedDocuments, document_id]
    );
  };

  return (
    <>
      {showNewRequest && (
        <ClientDocumentsRequest
          show={showNewRequest}
          onCancel={() => setShowNewRequest(false)}
          onSuccess={() => setShowNewRequest(false)}
          data={{ client_id }}
        />
      )}

      {showNewFolder && (
        <ClientDocumentsAddFolder
          show={showNewFolder}
          onCancel={() => setShowNewFolder(false)}
          onSuccess={() => setShowNewFolder(false)}
          data={{ client_id }}
        />
      )}

      <Card>
        <CardHeader
          title="Documents"
          rightButtons={
            <>
              {selectedDocuments.length > 0 && (
                <Button
                  size="small"
                  theme="danger"
                  title="Delete Selected Files"
                  onClick={handleDeleteMultiDocuments}
                />
              )}
              <Button
                size="small"
                theme="primary"
                title="Create New Folder"
                onClick={() => setShowNewFolder(true)}
              />
              <Button
                size="small"
                title="Document Requests"
                theme="primary"
                onClick={() => navigate(`../document-requests`)}
              />
              <Button
                size="small"
                theme="primary"
                title="Ask for documents"
                onClick={() => setShowNewRequest(true)}
              />
            </>
          }
        />
        <div className="asr-documents-wrapper">
          {folderList.map((itm, index) => (
            <div className="document-item" key={index}>
              <div className="document-icon">
                <IconFolder size={60} onClick={() => setQueryParams({ ...queryParams, folder_id: itm.folder_id })} />
              </div>
              <p className="document-title">{itm?.folder_name}</p>
              {/* <br /> */}
              {/* <p style={{ textAlign: 'center' }}><IconDelete size={25} title="Delete" onClick={() => confirmPopUp('Are you sure you want to delete this folder?', () => handleDeleteDocumentFolder(itm?.folder_id))} /></p> */}
            </div>
          ))}

          {documentList.map((itm, index) => (
            <DocumentItem
              key={index}
              itm={itm}
              handleDeleteDocument={handleDeleteDocument}
              isSelected={selectedDocuments.includes(itm?.id)}
              toggleSelectDocument={toggleSelectDocument}
            />
          ))}
        </div>
      </Card>
    </>
  );
};

export default ClientDocuments;

const DocumentItem = ({
  itm,
  handleDeleteDocument,
  isSelected,
  toggleSelectDocument,
}: {
  itm: any;
  handleDeleteDocument: (document_id: number, drive_document_id: string) => void;
  isSelected: boolean;
  toggleSelectDocument: (document_id: number) => void;
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const ulRef = useRef<HTMLUListElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ulRef.current && !ulRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchPreview = (drive_document_id: string, type: string) => {
    setShowViewModal(true);
  };

  // const handleDownloadClick = (e: React.MouseEvent, downloadUrl: string) => {
  //   e.stopPropagation();
  //   window.open(downloadUrl);
  // };
  const handleDownloadClick = (e: React.MouseEvent, downloadUrl: string) => {
    e.stopPropagation();
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleDeleteClick = (e: React.MouseEvent, documentId: number, driveDocumentId: string) => {
    e.stopPropagation();
    confirmPopUp('Are you sure you want to delete?', () => handleDeleteDocument(documentId, driveDocumentId));
  };

  return (
    <div className="document-item">
      <div style={{ position: 'absolute', right: '5px', top: '5px' }}>
        <IconOptions onClick={() => setShowOptions(!showOptions)} />
      </div>
      {showOptions && (
        <ul ref={ulRef} style={{ position: 'absolute', right: '20px', top: '10px', background: 'white', padding: '10px', borderRadius: '11px', lineHeight: 1.8 }}>
          <li onClick={(e) => handleDownloadClick(e, itm?.download_url)}>Download</li>
          <li onClick={(e) => handleDeleteClick(e, itm?.id, itm?.drive_document_id)}>Delete</li>
        </ul>
      )}
      <div className="document-icon">
        {itm?.file_type === 'image' ? (
          <IconImage size={60} onClick={() => fetchPreview(itm?.drive_document_id, 'image')} />
        ) : itm?.file_type === 'pdf' ? (
          <IconPdf size={60} onClick={() => fetchPreview(itm?.drive_document_id, 'pdf')} />
        ) : (
          <IconFile size={60} />
        )}
      </div>
      <p className="document-title">{itm?.document_name}</p>
        <input
        style={{ position: 'absolute', left: '5px', top: '5px',background:'pink' }}
          type="checkbox"
          checked={isSelected}
          onChange={() => toggleSelectDocument(itm?.id)}
        />
      
      <p style={{ textAlign: 'center' }}>
        {/* <IconDelete size={25} title="Delete" onClick={(e: any) => handleDeleteClick(e, itm?.id, itm?.drive_document_id)} />
        &nbsp;&nbsp; */}
        {/* <a href={itm?.download_url} download>
          <IconFileDownload size={25} title="Download" />
        </a> */}
      </p>

      {showViewModal && (
        <ClientDocumentView
          show={showViewModal}
          onCancel={() => setShowViewModal(false)}
          onSuccess={() => { }}
          data={{ drive_document_id: itm?.drive_document_id }}
        />
      )}
    </div>
  );
};
