import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useListFilters({ listName }: { listName?: string | null }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterList,setFilterList] = useState<Record<string, any>>({})
  

  useEffect(()=>{
    setFilterList(Object.fromEntries(searchParams))
  },[searchParams])
  

  const setFilter=(key:string,value:string | null | number | undefined)=>{
      const newUrl = new URL(window.location.href);
      if(typeof value == 'string'){
        newUrl.searchParams.set(key, value);
      } else {
        newUrl.searchParams.delete(key);
      }
      setSearchParams(newUrl.search);
  }




  return {
    filters:filterList,
    setFilter
  };
}
